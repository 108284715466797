<template>
  <LoadingBlock :loading="companyLoading">
    <form @submit.prevent="companySubmit">
      <input type="hidden" name="id" class="form-control" placeholder="" v-model="item_data.id">
      <div class="col mb-4">
        <label class="form-label">Название компании</label>
        <input type="text" name="name" class="form-control" placeholder="" required v-model="item_data.name">
      </div>
      <div class="col mb-4">
        <label class="form-label">База для расчета налогов</label>
        <Select v-model="item_data.tax_type" :options="taxTypes"
                optionLabel="name" optionValue="code" placeholder="" style="width: 100%"/>
      </div>
      <div class="col mb-4">
        <label class="form-label">Процент налога</label>
        <input type="text" name="tax_percent" class="form-control" placeholder="" v-model="item_data.tax_percent">
      </div>
      <div class="col mb-4 text-center">
        <button type="submit" class="btn btn-primary me-4 waves-effect waves-light">Сохранить</button>
      </div>
    </form>
  </LoadingBlock>
</template>
<script>
import LoadingBlock from "@/components/page/LoadingBlock.vue";
import {Message, Messages} from "@/data/messages";
import {Select} from "primevue";
import {editCompany} from "@/api/company";
import {taxTypes} from "@/data/company"


export default {
  name: "CompanyForm",
  components: {LoadingBlock, Select},
  data() {
    return {
      companyLoading: false,
      taxTypes: taxTypes
    }
  },
  computed: {
    item_data() {
      return {...this.item}
    }
  },
  props: {
    item: {
      type: Object
    }
  },
  methods: {
    async companySubmit() {
      this.companyLoading = true
      const message = new Message()
      try {
        const result = await editCompany(this.item_data)
        if (result.status === 200 || result.status === 201) {
          message.type = 'success'
          message.autoremove = 10
          message.text = 'Настройки успешно применены'
          this.$emit('companySuccess', this.item_data)
        } else {
          message.type = 'failure'
          message.text = 'При создании/обновлении компании произошла ошибка. Проверьте данные, попробуйте снова или обратитесь в поддержку.'
        }
      } catch (e) {
        message.type = 'failure'
        message.text = 'При создании/обновлении компании произошла ошибка. Проверьте данные, попробуйте снова или обратитесь в поддержку.'
      }
      Messages.list.push(message)
      this.companyLoading = false
    }
  },
}
</script>