<template>
  <BaseLayout>
    <div class="row mb-12 g-6">
      <div class="col-xl-4 col-lg-5 order-1 order-md-0">
        <LoadingBlock :loading="loadingUserInfo">
          <div class="card">
            <div class="card-body pt-12">
              <h5 class="pb-4 border-bottom mb-4">{{ user_info.name }}</h5>
              <ul class="list-unstyled mb-6">
                <li class="mb-2">
                  <span class="h6">Эл. почта: </span>
                  <span>{{ user_info.email }}</span>
                </li>
              </ul>
            </div>
          </div>
        </LoadingBlock>
      </div>
      <div class="col-xl-8 col-lg-7 order-0 order-md-1">
        <LoadingBlock :loading="loadingCompanyInfo">
          <div class="card">
            <div class="card-body pt-12">
              <h5 class="pb-4 border-bottom mb-4">Информация о компании</h5>
              <ul class="list-unstyled mb-6">
                <li class="mb-2">
                  <span class="h6">Название: </span>
                  <span>{{ company_info.name }}</span>
                </li>
                <li class="mb-2">
                  <span class="h6">База для расчета налогов: </span>
                  <span>{{ getTaxName(company_info.tax_type) }}</span>
                </li>
                <li class="mb-2">
                  <span class="h6">Процент налога: </span>
                  <span>{{ company_info.tax_percent }}</span>
                </li>
              </ul>
              <div class="demo-inline-spacing">
                <button class="btn btn-outline-primary waves-effect" @click="editCompany()">
                  Редактировать
                </button>
              </div>
            </div>
          </div>
        </LoadingBlock>
      </div>
    </div>
    <BaseModal ref="companyModal" modal-title="Настройки компании">
      <CompanyForm :item="company_info" @company-success="companySuccess"/>
    </BaseModal>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/layouts/Base.vue";
import {PageInfo} from "@/data/page";
import {user} from "@/data/user";

import LoadingBlock from "@/components/page/LoadingBlock.vue";
import {getUser} from "@/api/user";
import {getCompany} from "@/api/company";
import BaseModal from "@/components/Modal.vue";
import CompanyForm from "@/components/forms/Company.vue";
import {taxTypes} from "@/data/company";

export default {
  name: 'SettingsView',
  components: {CompanyForm, BaseModal, LoadingBlock, BaseLayout},
  data() {
    return {
      company_info: {},
      user_info: {},
      loadingUserInfo: true,
      loadingCompanyInfo: true
    }
  },
  directives: {},
  methods: {
    async setUserData() {
      this.loadingUserInfo = true
      const result = await getUser(user.user_id)
      this.user_info = result.data
      this.loadingUserInfo = false
    },
    async setCompanyData() {
      this.loadingCompanyInfo = true
      const result = await getCompany(user.active_company)
      this.company_info = result.data
      this.loadingCompanyInfo = false
    },
    editCompany() {
      this.$refs.companyModal.openModal()
    },
    companySuccess(item) {
      this.company_info = {...item}
      this.$refs.companyModal.closeModal()
    },
    getTaxName(code) {
      const tax = taxTypes.find((item) => item.code === code)
      if (typeof tax !== 'undefined') {
        return tax.name
      }
    },
    async setPage() {
      PageInfo.title = 'Настройки'
      PageInfo.breadcrumbs = [
        {
          title: 'Главная',
          link: '/'
        },
        {
          title: PageInfo.title
        }
      ]
      await user.getUserBase()
      await this.setUserData()
      await this.setCompanyData()
    }
  },
  async mounted() {
    await this.setPage()
  },
  watch: {
    '$route'() {
      this.setPage()
    }
  }
}
</script>