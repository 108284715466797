<template>
  <BaseLayout>
    <div class="card card-fullscreen">
      <div class="card-body">
        <LoadingBlock :loading="loadingSettings">
          <div class="row gy-sm-1">
            <div class="col-md-3 mt-2">
              <label class="form-label">Поиск по товарам</label>
              <input type="text" class="form-control border-1 py-2" placeholder="Название, код товара, бренд"
                     v-model="searchData.name" @change="search">
            </div>
            <div class="col-md-2 mt-2">
              <label class="form-label">Магазины</label>
              <MultiSelect v-model="activeStores" :options="stores" optionLabel="name" optionValue="id"
                           :showToggleAll="false"
                           @change="setActiveStores"
                           placeholder="Магазин не выбран"
                           :maxSelectedLabels="3" style="width: 100%">
                <template #option="slotProps">
                  <div class="d-flex align-content-center">
                    <img :src="slotProps.option.icon" alt="Маркетплейс" width="20px" style="margin-right: 3px">
                    <span>{{ slotProps.option.name }}</span>
                  </div>
                </template>
              </MultiSelect>

            </div>

            <div class="col-md-2 mt-2">
              <label class="form-label">Модель работы</label>
              <Select v-model="OrderType" :options="OrderTypes"
                      optionLabel="name" optionValue="value" placeholder="Модель работы" style="width: 100%"
                      @change="updateSettings"/>

            </div>

            <div class="col-md-3 mt-2">
              <label class="form-label">Столбцы</label>
              <MultiSelect v-model="SelectedColumns" :options="columns"
                           optionLabel="name" optionValue="code" placeholder="Столбцы" style="width: 100%"
                           :showToggleAll="false"
                           @change="updateSettings"/>

            </div>
            <div class="col-md-2 mt-2 align-content-end" style="text-align: right">
              <button class="btn w-100 btn-outline-secondary waves-effect mb-1" @click="openSettings">Больше настроек
              </button>
            </div>
          </div>
          <div v-if="!products.length && !loadingProducts" class="mt-7">
            Товары не найдены
          </div>
        </LoadingBlock>
      </div>
      <div style="height: 100%">
        <LoadingBlock :loading="loadingOffers || loadingProducts">
          <ag-grid-vue ref="pricesGrid" v-if="products.length"
                       :rowData="rowData"
                       :columnDefs="colDefs"
                       :onCellValueChanged="onCellValueChanged"
                       :copySelectedRows="true"
                       :tooltipInteraction="true"
                       :tooltipShowDelay="0"
                       :getRowId="getRowId"
                       :gridOptions="gridOptions"
                       suppressNoRowsOverlay="true"
                       @grid-ready="onGridReady"
                       domLayout="autoHeight"
                       style="width: 100%;"
                       class="ag-theme-quartz"
          >
          </ag-grid-vue>

          <BasePagination :info="paginationInfo" ref="productsPagination" @changePage="changePage">
          </BasePagination>
        </LoadingBlock>
      </div>
    </div>
    <OffCanvas ref="priceCanvas" :title="'Настройки'">
      <h6 class="mb-1">Загрузить цены из маркетплейса</h6>
      <div class="row">
        <div class="col-md-6 mt-2">
          <label class="form-label">Магазин</label>
          <Select v-model="storeToUpdate" :options="stores" optionLabel="name" optionValue="id"
                  placeholder="Магазин не выбран"
                  style="width: 100%"/>
        </div>
        <div class="col-md-6 align-content-end mt-2">
          <ButtonComponent ref="getOffersButton" :class="'btn w-100 btn-outline-secondary waves-effect'"
                           @click="storeGetOffers()">Загрузить
          </ButtonComponent>
        </div>
      </div>
    </OffCanvas>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/layouts/Base.vue'
import {PageInfo} from "@/data/page";
import {user} from "@/data/user";
import {stores} from "@/data/store";
import {price} from "@/data/price";
import {getProducts, updateCosts, updateMinPrices} from "@/api/product";
import BasePagination from "@/components/list/Pagination.vue"
import MarketplaceTooltip from "@/components/price/Marketplace.vue"
import {getOffers as StoreGetOffers} from "@/api/store"

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

import {AgGridVue} from "ag-grid-vue3";
import {getOffers, setOffers} from "@/api/offer";
import {getChangeInfo, isEditable} from "@/helpers/price";
import LoadingBlock from "@/components/page/LoadingBlock.vue";
import MultiSelect from 'primevue/multiselect';
import {Select} from "primevue";
import {Message, Messages} from "@/data/messages";
import OffCanvas from "@/components/page/OffCanvas.vue";
import StoreLink from "@/components/price/StoreLink.vue";
import ButtonComponent from "@/components/tools/Button.vue";
import {afterStoreAction} from "@/helpers/store";

export default {
  name: 'PricesView',
  // eslint-disable-next-line vue/no-unused-components
  components: {
    ButtonComponent,
    OffCanvas,
    LoadingBlock, BaseLayout, BasePagination, AgGridVue, MultiSelect, Select
  },
  data() {
    return {
      stores: [],
      loadingSettings: true,
      loadingOffers: false,
      loadingProducts: true,
      activeStores: [],
      storeToUpdate: '',
      OrderType: 'fbs',
      products: [],
      paginationInfo: {},
      searchData: {
        name: '',
      },
      rowData: [],
      fixedCols: [
        {field: "name", headerName: 'Товар', width: 500, sortable: false, editable: true},
      ],
      colDefs: [],
      gridApi: {},
      gridOptions: {
        suppressMovableColumns: true,
        rowSelection: {
          mode: 'singleRow',
          checkboxes: false,
          enableClickSelection: true,
        },
        rowClassRules: {
          'row-in-process': (params) => {
            return params.data.in_process;
          },
        }
      },
      OrderTypes: [
        {
          name: 'FBS',
          value: 'fbs',
        },
        {
          name: 'FBO',
          value: 'fbo',
        }
      ],
      SelectedColumns: [],
      columns: [
        {
          name: 'Артикул',
          code: 'product_code'
        },
        {
          name: 'Себестоимость',
          code: 'cost'
        },
        {
          name: 'РРЦ',
          code: 'min_price'
        },
        {
          name: 'Цена в магазине',
          code: 'price'
        },
        {
          name: 'Прибыль',
          code: 'profit'
        },
        {
          name: 'Налог',
          code: 'tax'
        },
        {
          name: 'Процент прибыли',
          code: 'profit_percent'
        },
        {
          name: 'Комиссия',
          code: 'commission'
        },
        {
          name: 'Ссылка на карточку',
          code: 'store_link'
        }
      ]
    }
  },
  methods: {
    async getProducts() {
      this.loadingProducts = true
      const result = await getProducts(user.active_company, this.$route.query)
      this.paginationInfo = {
        total: result.data.total,
        pages: result.data.pages,
        page: result.data.page,
        size: result.data.size
      }
      this.products = result.data.items
      this.setRows()
      this.loadingProducts = false
      await this.setCols()
    },
    async search() {
      const query = {...this.$route.query}
      delete query.page
      Object.entries(this.searchData).forEach(([key, value]) => {
        if (value) {
          query[key] = value
        } else {
          delete query[key]
        }
      });
      await this.$router.push({
        path: this.$route.path,
        query: {
          ...query
        }
      })

    },
    setSearch() {
      if (this.$route.query.name) {
        this.searchData.name = this.$route.query.name
      } else {
        this.searchData.name = ''
      }
      if (this.$route.query.brand) {
        this.searchData.brand = this.$route.query.brand
      } else {
        this.searchData.brand = ''
      }
    },
    async changePage(page) {
      await this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: page
        }
      });
    },
    setPageInfo() {
      PageInfo.title = 'Ценообразование'
      PageInfo.breadcrumbs = [
        {
          title: 'Главная',
          link: '/'
        },
        {
          title: PageInfo.title
        }
      ]
    },
    updateSettings() {
      price.setSettings({
            columns: this.SelectedColumns,
            orderType: this.OrderType
          }
      )
      this.setRows()
      this.setCols()
    },
    async setActiveStores() {
      await stores.setActiveStores(this.activeStores)
      this.setRows()
      await this.setCols()
    },
    setRows() {
      this.rowData = []
      this.products.forEach((product) => {
        this.rowData.push(
            {
              id: product.id,
              code: product.code,
              name: product.name,
              cost: product.cost,
              in_process: false,
              min_price: product.min_price,
              context: {
                code: product.code
              }
            }
        )
      })
    },
    async setCols() {
      this.colDefs = []
      this.colDefs = [...this.fixedCols]
      if (this.SelectedColumns.includes('product_code')) {
        this.colDefs.push(
            {
              field: "code", headerName: 'Артикул', width: 100, sortable: false, editable: true,
              valueParser: (params) => {
                return params.newValue
              }
            },
        )
      }
      if (this.SelectedColumns.includes('cost')) {
        this.colDefs.push(
            {field: "cost", headerName: 'Себестоимость', width: 100, sortable: false, editable: true},
        )
      }
      if (this.SelectedColumns.includes('min_price')) {
        this.colDefs.push(
            {field: "min_price", headerName: 'РРЦ', width: 100, sortable: false, editable: true},
        )
      }
      this.activeStores.forEach((store) => {
        const store_info = stores.stores.find(obj => obj['id'] === store);
        const col_context = {
          marketplace: store_info.marketplace,
          store_id: store_info.id
        }
        let children = []

        if (this.SelectedColumns.includes('price')) {
          children.push(
              {
                field: store_info.id + '_price',
                headerName: 'Цена',
                editable: (params) => isEditable(params),
                sortable: false,
                width: 100,
                context: col_context,
              }
          )
        }
        if (this.SelectedColumns.includes('profit')) {
          children.push(
              {
                field: store_info.id + '_profit_amount',
                headerName: 'Прибыль',
                editable: (params) => isEditable(params),
                sortable: false,
                width: 100,
                context: col_context,
              }
          )
        }
        if (this.SelectedColumns.includes('profit_percent')) {
          children.push(
              {
                field: store_info.id + '_profit_percent',
                headerName: 'Процент прибыли',
                editable: (params) => isEditable(params),
                sortable: false,
                width: 100,
                context: col_context,
              }
          )
        }
        if (this.SelectedColumns.includes('commission')) {
          children.push(
              {
                field: store_info.id + '_commission',
                headerName: 'Комиссия',
                sortable: false,
                width: 100,
                tooltipComponent: MarketplaceTooltip,
                tooltipValueGetter: (p) => {
                  return p
                },
                context: {
                  store_id: store_info.id,
                  marketplace: store_info.marketplace
                }
              }
          )
        }
        if (this.SelectedColumns.includes('tax')) {
          children.push(
              {
                field: store_info.id + '_tax',
                headerName: 'Налог',
                sortable: false,
                width: 100,
              }
          )
        }

        if (this.SelectedColumns.includes('store_link')) {
          children.push(
              {
                field: store_info.id + '_store_link',
                headerName: '',
                editable: false,
                cellRenderer: StoreLink,
                sortable: false,
                width: 60,
              }
          )
        }
        this.colDefs.push(
            {
              field: store_info.id, headerName: store_info.name, minWidth: 500,
              children: children
            }
        )
      })
      await this.loadOffers()
    },
    async loadOffers(products = null) {
      if (!products) {
        products = this.products.map((product) => product.id)
      }
      if (!products.length || !this.activeStores.length) {
        return
      }

      this.loadingOffers = true
      const company_id = await user.getActiveCompany()
      const offers = await getOffers(company_id, this.OrderType, this.activeStores, products)
      offers.data.items.forEach((offer) => this.setOffer(offer))
      this.loadingOffers = false
    },
    setOffer(offer) {
      const row = this.rowData.find(row => offer['product_id'] === row.id)
      row[offer['store_id'] + '_price'] = offer['price']
      row[offer['store_id'] + '_profit_amount'] = offer['profit_info']['profit_amount']
      row[offer['store_id'] + '_profit_percent'] = offer['profit_info']['profit_percent']
      row[offer['store_id'] + '_commission'] = offer['profit_info']['commission_amount']
      row[offer['store_id'] + '_tax'] = offer['profit_info']['tax_amount']
      row[offer['store_id'] + '_offer_id'] = offer['id']
      row[offer['store_id'] + '_store_link'] = offer['store_url']
      row[offer['store_id']] = {
        offer_id: offer['id'],
        store_info: offer['store_info'],
        profit_info: offer['profit_info'],
        store_url: offer['store_url']
      }
      const rowNode = this.gridApi.value.getRowNode(offer['product_id']);
      rowNode.setData(row)
    },
    async onCellValueChanged(e) {
      const change = getChangeInfo(e)
      if (!this.checkValueBeforeUpdate(change)) {
        e.data[e.colDef.field] = e.oldValue
        this.gridApi.value.redrawRows()
        return
      }
      if (change.what_to_change === 'price' || change.what_to_change === 'profit' || change.what_to_change === 'profit_percent') {
        if (change.info) {
          this.beforePriceChange(e)
          change.info.order_type = this.OrderType
          const offers = [change.info]
          const result = await setOffers(user.active_company, offers)
          this.afterPriceChange(result, e)
        }
      }
      if (change.what_to_change === 'cost') {
        this.beforePriceChange(e)
        const result = await updateCosts(user.active_company, [change.info.data])
        console.log(result)
        e.data.in_process = false
        await this.loadOffers([change.info.product_id])
      }
      if (change.what_to_change === 'min_price') {
        this.beforePriceChange(e)
        const result = await updateMinPrices(user.active_company, [change.info.data])
        e.data.in_process = false
        this.gridApi.value.redrawRows()
        console.log(result)
      }
    },
    checkValueBeforeUpdate(change) {
      if (change.what_to_change === 'price' || change.what_to_change === 'cost' || change.what_to_change === 'min_price') {
        if (change.info['difference'] > 0.2) {
          return confirm('Новое значение отличается от старой больше чем на 20%, вы уверены, что хотите продолжить?');
        }
      }
      if (change.what_to_change === 'profit' || change.what_to_change === 'profit_percent') {
        if (change.info['difference'] > 0.3) {
          return confirm('Новое значение отличается от старой больше чем на 30%, вы уверены, что хотите продолжить?');
        }
      }
      return true
    },
    beforePriceChange(e) {
      e.data.in_process = true
      this.gridApi.value.redrawRows()
    },
    afterPriceChange(result, e) {
      e.data.in_process = false
      result.data['offers'].forEach((offer) => this.setOffer(offer))
      let success = result.data['results'].filter((item) => item['result'] === 'success')
      let failure = result.data['results'].filter((item) => item['result'] === 'failure')
      if (success.length) {
        success = success.map((
            {product_code}) => (product_code));
        const text = 'Цены успешно обновлены для товаров: ' + success.join(',') + '.'
        const message = new Message()
        message.text = text
        message.type = 'success'
        message.autoremove = 5
        message.format = 'notification'
        Messages.list.push(message)
      }
      if (failure.length) {
        failure = failure.map((
            {message, product_code}) => (product_code + ' (' + message + ')'));
        const text = 'Ошибка при обновлении цен: ' + failure.join(',') + '.'
        const message = new Message()
        message.text = text
        message.type = 'failure'
        message.format = 'notification'
        Messages.list.push(message)
      }
    },
    onGridReady(params) {
      this.gridApi.value = params.api;
    },
    getRowId(params) {
      return params.data.id;
    },
    openSettings() {
      this.$refs.priceCanvas.open()
    },
    closeSettings() {
      this.$refs.priceCanvas.close()
    },
    async storeGetOffers() {
      if (this.storeToUpdate.length) {
        this.$refs.getOffersButton.startLoading()
        const result = await StoreGetOffers(this.storeToUpdate)
        afterStoreAction(result)
        this.$refs.getOffersButton.endLoading()
        this.closeSettings()
        await this.loadOffers()
      }
    }
  },
  async mounted() {
    this.setPageInfo()
    this.setSearch()
    await user.getUserBase()
    this.stores = await stores.getStores()
    this.activeStores = await stores.getActiveStores()
    const settings = price.getSettings()
    this.SelectedColumns = settings.columns
    this.OrderType = settings.orderType
    this.loadingSettings = false
    await this.getProducts()
  },
  watch: {
    '$route'() {
      this.setSearch()
      this.setPageInfo()
      this.getProducts()
    }
  }
}
</script>

<style>
.ag-theme-quartz {
  --ag-header-background-color: white;
  --ag-wrapper-border-radius: 0;
}

.ag-root-wrapper {
  border: 0;
}

.ag-body-viewport {
  min-height: 650px;
}

.row-in-process {
  background-color: #bee2ff;
}
</style>
