import axios from "axios";
import {API_URL} from "@/api/base";
import {auth} from "@/data/auth";


export async function getCompanies(){
    return await axios.get(
        `${API_URL}/v1/company/user_companies`,
        {headers: {'Authorization': `Bearer ${await auth.getAccessToken()}`}}
    )
}

export async function getCompany(company_id){
    return await axios.get(
        `${API_URL}/v1/company/${company_id}/info`,
        {headers: {'Authorization': `Bearer ${await auth.getAccessToken()}`}}
    )
}

export async function editCompany(data){
    return await axios.post(
        `${API_URL}/v1/company/${data.id}/update`,
        data,
        {headers: {'Authorization': `Bearer ${await auth.getAccessToken()}`}}
    )
}